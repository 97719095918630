import {
  BooleanField, ChipField,
  DateField,
  FormTab, ReferenceArrayField,
  ReferenceField,
  SelectField, SingleFieldList,
  TextField,
  useShowController
} from 'react-admin'
import { eventTypeChoices } from '../../../constants'
import React from 'react'
import { useSelector } from 'react-redux'

export const EventDetailsTab = (props) => {
  const { record } = useShowController(props)
  const { extraFieldLabel, secondExtraFieldLabel } = useSelector(state => state.property)

  return (
    <FormTab label='Event Summary' {...props}>
      <DateField
        label='Event date'
        source='date'
        showTime
      />
      <TextField
        source='guestName'
        label='Guest Name'
      />
      <ReferenceField
        source='hostId'
        label='Host'
        reference='mobileUsers'
        link={false}
      >
        <TextField source='name' />
      </ReferenceField>
      <ReferenceField
        source='flatId'
        label='Unit Number'
        reference='flats'
      >
        <TextField source='flatNumber' />
      </ReferenceField>
      <ReferenceArrayField
        source='additionalFlatsIds'
        label='Additional Units'
        reference='flats'
      >
        <SingleFieldList>
          <ChipField source='flatNumber' style={{ fontSize: 16 }} variant='outlined' color='primary' />
        </SingleFieldList>
      </ReferenceArrayField>
      <ReferenceField
        source='deviceId'
        label='Device'
        reference='devices'
      >
        <TextField source='name' />
      </ReferenceField>
      <SelectField
        source='type'
        choices={eventTypeChoices}
      />
      <TextField
        source='message'
        label='Message'
      />
      {record.entryType ? <TextField source='entryType' style={{ textTransform: 'capitalize' }} label='Entry Type' /> : null}
      {record.leaveType ? <TextField source='leaveType' style={{ textTransform: 'capitalize' }} label='Leave Type' /> : null}
      {record.isDigitalCard ? <BooleanField source='isDigitalCard' label='Created by Digital Card' /> : null}
      {record.guardName ? <TextField source='guardName' label='Guard Name' /> : null}
      {record.resolveNotes ? <TextField source='resolveNotes' label='Resolve notes' /> : null}
      {record.accessId ? (<ReferenceField link={false} source='accessId' label='Access Title/Note' reference='accesses'><TextField source='title' /></ReferenceField>) : null}
      {extraFieldLabel && record.extraField ? <TextField source='extraField' label={extraFieldLabel} /> : null}
      {secondExtraFieldLabel && record.secondExtraField ? <TextField source='secondExtraField' label={secondExtraFieldLabel} /> : null}
    </FormTab>
  )
}
